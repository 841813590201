/* Global styles (styles.css) */
.container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column; /* Stack elements on smaller screens */
        padding: 0.5rem; /* Adjust padding */
    }

    .button {
        width: 100%; /* Full width buttons on mobile */
    }

    .input {
        width: 100%; /* Full width inputs on mobile */
    }

    .grid {
        grid-template-columns: 1fr; /* Stack grid items on mobile */
    }
}

/* Responsive image */
img {
    max-width: 100%;
    height: auto;
}

/* Ensure cards and other components are responsive */
.card {
    width: 100%; /* Full width for cards */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Footer adjustments */
footer {
    padding: 1rem;
    font-size: 0.875rem; /* Smaller text on mobile */
}
